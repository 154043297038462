import Vue from "vue"
import VueRouter from "vue-router"
import "../store"

import { AuthMiddleware, PageNotFound, Routes } from "@syntax51/app-kit"
import * as Pages from "@/pages"

Vue.use(VueRouter)

const routes = Routes.concat([
  {
    name: "account-types",
    path: "/account-types",
    component: Pages.AccountTypes
  },
  {
    name: "account-types.view",
    path: "/account-types/:id",
    component: Pages.AccountTypesView,
    redirect: to => `${to.path}/permissions`,
    children: [
      {
        name: "account-types.view.permissions",
        path: "permissions",
        component: Pages.AccountTypesViewPermissions
      },
      {
        name: "account-types.view.user-templates",
        path: "user-templates",
        component: Pages.AccountTypesViewUserTemplates
      }
    ]
  },
  {
    name: "api-logs",
    path: "/api-logs",
    component: Pages.ApiLogs
  },
  {
    name: "collections",
    path: "/collections",
    component: Pages.Collections
  },
  {
    name: "collections.view",
    path: "/collections/:id",
    component: Pages.CollectionsView
  },
  {
    name: "content",
    path: "/content",
    component: Pages.Content
  },
  {
    name: "content.new",
    path: "/content/new",
    component: Pages.AddEditContent
  },
  {
    name: "content.edit",
    path: "/content/:id/edit",
    component: Pages.AddEditContent
  },
  {
    name: "copilot-insights",
    path: "/copilot-insights",
    component: Pages.CopilotInsights
  },
  {
    name: "copilot-insights.partner",
    path: "/copilot-insights/partner/:id",
    component: Pages.CopilotPartnerInsights
  },
  {
    name: "copilot-insights.user",
    path: "/copilot-insights/user/:id",
    component: Pages.CopilotUserInsights
  },
  {
    name: "countries",
    path: "/countries",
    component: Pages.Countries
  },
  {
    name: "countries.view",
    path: "/countries/:id",
    component: Pages.CountriesView
  },
  {
    name: "image.new",
    path: "/image/new",
    component: Pages.AddEditImage
  },
  {
    name: "image.edit",
    path: "/image/:id/edit",
    component: Pages.AddEditImage
  },
  {
    name: "consortia",
    path: "/consortia",
    component: Pages.Consortia
  },
  {
    name: "content-depths",
    path: "/content-depths",
    component: Pages.ContentDepths
  },
  {
    name: "content-domains",
    path: "/content-domains",
    component: Pages.ContentDomains
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: Pages.Dashboard
  },
  {
    name: "destinations",
    path: "/destinations",
    component: Pages.Destinations
  },
  {
    name: "expired-content",
    path: "/expired-content",
    component: Pages.ExpiredContent
  },
  {
    name: "facades",
    path: "/facades",
    component: Pages.Facades
  },
  {
    name: "host-agencies",
    path: "/host-agencies",
    component: Pages.HostAgencies
  },
  {
    name: "languages",
    path: "/languages",
    component: Pages.Languages
  },
  {
    name: "libraries",
    path: "/libraries",
    component: Pages.Libraries
  },
  {
    name: "media-types",
    path: "/media-types",
    component: Pages.MediaTypes
  },
  {
    name: "partner-markets",
    path: "/partner-markets",
    component: Pages.PartnerMarkets
  },
  {
    name: "partners",
    path: "/partners",
    component: Pages.Partners
  },
  {
    name: "partners.view",
    path: "/partners/:id",
    component: Pages.PartnersView,
    redirect: to => `${to.path}/details`,
    children: [
      {
        name: "partners.view.details",
        path: "details",
        component: Pages.PartnersViewDetails
      },
      {
        name: "partners.view.copilot",
        path: "copilot",
        component: Pages.PartnersViewCopilot
      },
      {
        name: "partners.view.customizations",
        path: "customizations",
        component: Pages.PartnersViewCustomizations
      },
      {
        name: "partners.view.collections",
        path: "collections/:folder_id?",
        component: Pages.PartnersViewCollections
      },
      {
        name: "partners.view.users",
        path: "users",
        component: Pages.PartnersViewUsers
      }
    ]
  },
  {
    name: "publishers",
    path: "/publishers",
    component: Pages.Publishers
  },
  {
    name: "regions",
    path: "/regions",
    component: Pages.Regions
  },
  {
    name: "search-history",
    path: "/search-history",
    component: Pages.SearchHistory
  },
  {
    name: "shared-collections",
    path: "/shared-collections",
    component: Pages.SharedCollections
  },
  {
    name: "shared-collections.view",
    path: "/shared-collections/:id",
    component: Pages.SharedCollectionsView
  },
  {
    name: "subject-areas",
    path: "/subject-areas",
    component: Pages.SubjectAreas
  },
  {
    name: "users",
    path: "/users",
    component: Pages.Users
  },
  {
    name: "users.view",
    path: "/users/:id",
    component: Pages.UsersView,
    redirect: to => `${to.path}/details`,
    children: [
      {
        name: "users.view.details",
        path: "details",
        component: Pages.UsersViewDetails
      },
      {
        name: "users.view.collections",
        path: "collections/:folder_id?",
        component: Pages.UsersViewCollections
      },
      {
        name: "users.view.sharedCollections",
        path: "shared-collections",
        component: Pages.UsersViewSharedCollections
      }
    ]
  },
  {
    name: "export",
    path: "/export",
    component: Pages.Export
  },
  {
    name: "404",
    path: "/404",
    component: PageNotFound,
    meta: { auth: false }
  },
  {
    name: "catch-all",
    path: "*",
    redirect: { name: "404" }
  }
])

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(AuthMiddleware)

export default router
