<template>
  <AppLayout :title="title">
    <div class="grid grid-cols-4 gap-6 space-y-0 lg:gap-12">
      <Highlight
        label="Monthly Views"
        :count="38021"
        :previous-count="32832"
        difference-label="change from previous month"
      />
      <Highlight
        label="Monthly Leads"
        :count="1372"
        :previous-count="1238"
        difference-label="change from previous 7 days"
      />
      <Highlight
        label="Total Views"
        :count="223423"
        :previous-count="185402"
        difference-label="growth in the last 30 days"
      />
      <Highlight
        label="Total Leads"
        :count="3272"
        :previous-count="2839"
        difference-label="growth in the last 30 days"
      />
    </div>

    <DataTable
      title="Top Partners"
      :endpoint="`copilot-insights/user/${$route.params.id}/views-by-partner`"
      :per-page="10"
      :sort="{ views: 'desc' }"
      class="mt-6 lg:mt-12"
    >
      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn title="Partner" sortable="name">
            <router-link :to="{ name: 'copilot-insights.partner', params: { id: row.partner.id } }">{{
              row.partner.name
            }}</router-link>
          </DataTableColumn>
          <DataTableColumn title="Views" sortable="views">{{ row.views | number }}</DataTableColumn>
          <DataTableColumn title="Leads" sortable="leads" class="text-supplement">-</DataTableColumn>
        </DataTableRow>
      </template>
    </DataTable>

    <DataTable
      title="Top Pages"
      :endpoint="`copilot-insights/user/${$route.params.id}/views-by-page`"
      :per-page="10"
      :sort="{ views: 'desc' }"
    >
      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn title="Page" sortable="source_url">
            <div>{{ row.source_url }}</div>
            <div class="text-supplement">{{ row.partner.name }}</div>
          </DataTableColumn>
          <DataTableColumn title="Views" sortable="views">{{ row.views | number }}</DataTableColumn>
          <DataTableColumn title="Leads" sortable="leads" class="text-supplement">-</DataTableColumn>
        </DataTableRow>
      </template>
    </DataTable>

    <Card title="Top Sources">
      <div class="grid grid-cols-2">
        <div>Direct / None</div>
        <div>1M</div>
        <div>Google</div>
        <div>208k</div>
        <div>Twitter</div>
        <div>56k</div>
        <div>Facebook</div>
        <div>22.3k</div>
        <div>Reddit</div>
        <div>20k</div>
      </div>
    </Card>
  </AppLayout>
</template>

<script>
import Highlight from "@/components/Highlight.vue"

export default {
  components: { Highlight },
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    loading: true,
    user: null,
    title: "Copilot Insights"
  }),
  async created() {
    this.loading = true
    const { data } = await this.$api.get(`users/${this.$route.params.id}`)
    this.user = data.data
    this.title = `${this.user.full_name} Copilot Insights`
    this.loading = false
  }
}
</script>
