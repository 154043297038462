<template>
  <AppLayout :title="title">
    <div class="grid grid-cols-4 gap-6 space-y-0 lg:gap-12">
      <Highlight
        label="Monthly Views"
        :count="38021"
        :previous-count="32832"
        difference-label="change from previous month"
      />
      <Highlight
        label="Monthly Leads"
        :count="1372"
        :previous-count="1238"
        difference-label="change from previous 7 days"
      />
      <Highlight
        label="Total Views"
        :count="223423"
        :previous-count="185402"
        difference-label="growth in the last 30 days"
      />
      <Highlight
        label="Total Leads"
        :count="3272"
        :previous-count="2839"
        difference-label="growth in the last 30 days"
      />
    </div>

    <DataTable
      title="Top Partners"
      endpoint="copilot-insights/views-by-partner"
      :per-page="10"
      :sort="{ views: 'desc' }"
      class="mt-6 lg:mt-12"
    >
      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn title="Partner" sortable="name">
            <router-link :to="{ name: 'copilot-insights.partner', params: { id: row.partner.id } }">{{
              row.partner.name
            }}</router-link>
          </DataTableColumn>
          <DataTableColumn title="Views" sortable="views">{{ row.views | number }}</DataTableColumn>
          <DataTableColumn title="Leads" sortable="leads" class="text-supplement">-</DataTableColumn>
        </DataTableRow>
      </template>
    </DataTable>

    <DataTable title="Top Advisors" endpoint="copilot-insights/views-by-user" :per-page="10" :sort="{ views: 'desc' }">
      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn title="Partner" sortable="full_name">
            <div class="flex items-center">
              <Avatar
                :label="row.user.initials"
                :image-url="row.user.personal_photo && row.user.personal_photo.thumb_url"
                class="mr-5"
              />
              <div>
                <router-link :to="{ name: 'copilot-insights.user', params: { id: row.user.id } }">
                  <span v-if="row.user.full_name">{{ row.user.full_name }}</span>
                  <span v-else>Unknown</span>
                  <div class="text-supplement">{{ row.user.email }}</div>
                </router-link>
              </div>
            </div>
          </DataTableColumn>
          <DataTableColumn title="Views" sortable="views">{{ row.views | number }}</DataTableColumn>
          <DataTableColumn title="Leads" sortable="leads" class="text-supplement">-</DataTableColumn>
        </DataTableRow>
      </template>
    </DataTable>

    <div class="mt-12 grid gap-12 space-y-0 md:grid-cols-2">
      <Card title="Top Sources">
        <div class="grid grid-cols-2">
          <div>Direct / None</div>
          <div>1M</div>
          <div>Google</div>
          <div>208k</div>
          <div>Twitter</div>
          <div>56k</div>
          <div>Facebook</div>
          <div>22.3k</div>
          <div>Reddit</div>
          <div>20k</div>
        </div>
      </Card>

      <DataTable title="Top Pages" endpoint="copilot-insights/views-by-page" :per-page="10" :sort="{ views: 'desc' }">
        <template v-slot:rows="rows">
          <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
            <DataTableColumn title="Page" sortable="source_url">
              <div>{{ row.source_url }}</div>
              <div class="text-supplement">{{ row.partner.name }}</div>
            </DataTableColumn>
            <DataTableColumn title="Views" sortable="views">{{ row.views | number }}</DataTableColumn>
            <DataTableColumn title="Leads" sortable="leads" class="text-supplement">-</DataTableColumn>
          </DataTableRow>
        </template>
      </DataTable>
    </div>

    <div class="mt-12 grid gap-12 space-y-0 md:grid-cols-2">
      <Card title="Top Countries">
        <div>...</div>
      </Card>

      <Card title="Top Languages">
        <div>...</div>
      </Card>
    </div>

    <div class="mt-12 grid gap-12 space-y-0 md:grid-cols-2">
      <Card title="Top Devices">
        <div>...</div>
      </Card>

      <Card title="Top Browsers">
        <div>...</div>
      </Card>
    </div>
  </AppLayout>
</template>

<script>
import Avatar from "@/components/Avatar.vue"
import Highlight from "@/components/Highlight.vue"

export default {
  components: {
    Avatar,
    Highlight
  },
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Copilot Insights"
  })
}
</script>
